<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
      />
      <v-card
        v-for="(error, index) in formattedItem.errorsParsed"
        :key="index"
        flat
      >
        <v-card-title>{{ error.error.message }} ({{ error.error.code }})</v-card-title>
        <v-card-text>{{ error.error.data }}</v-card-text>
      </v-card>
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SyncUploadViewData'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'idsync_upload', label: 'Nº de sincronización' },
        { name: 'timestamp', label: 'Fecha', filter: this.$options.filters.shortDateTime },
        { name: 'count_ok', label: 'Cambios correctos' },
        { name: 'count_errors', label: 'Cambios con errores' },
        { name: 'count_total', label: 'Cambios totales' },
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idsync_upload
        var countSyncRealizados = item.count_ok + item.count_errors
        item.title = this.$options.filters.shortDateTime(item.timestamp)
        item.subtitle = `Cambios realizados: ${countSyncRealizados} de ${item.count_total}`
        if (countSyncRealizados !== parseInt(item.count_total)) {
          item.badge = 'Incompleta'
          item.badgeColor = 'warning'
        } else {
          if (item.count_errors > 0) {
            item.badge = 'Con errores'
            item.badgeColor = 'error'
          } else {
            item.badge = 'Correcta'
            item.badgeColor = 'success'
          }
        }
        if (item.errors) {
          item.errorsParsed = JSON.parse(item.errors)
        } else {
          item.errorsParsed = []
        }
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Sincronización Nº${this.item.dataset.idsync_upload}`
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectSyncUpload(this, this.routeParams.idsync_upload)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
  }
}
</script>
